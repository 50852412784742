import moment from "moment";
// type typesMap = {
//   users: User;
//   patients: Patient;
// };

// type MyInferredType<T extends keyof typesMap> = typesMap[T];

// const myVar = "users";
// let myType: MyInferredType<typeof myVar>;

export type Unite<A extends ReadonlyArray<unknown>> = A[number];

export type FetchesType<T> = {
  [Key in keyof T]: T[Key] extends
    | number
    | (number | null | undefined)
    | number[]
    | (number[] | null | undefined)
    | string
    | (string | null | undefined)
    | string[]
    | (string[] | null | undefined)
    | boolean
    ? never
    : Key;
}[keyof T];

export type TableName =
  | "users"
  | "specialities"
  | "roles"
  | "workingPlans"
  | "services"
  | "hospitals"
  | "units"
  | "boxes"
  | "beds"
  | "notifications"

  //
  | "refAnswers"
  | "fields"
  | "mesureSessions"
  | "fieldsSections"
  | "fieldTypes"
  | "fieldAnswers"
  | "fieldUnits"
  | "options"
  | "references"
  | "referencesGroups"
  | "scores"
  | "scoreIntervals"
  | "patients"
  | "uploads"
  | "messages"
  | "discussions"
  | "statisticsReports"
  | "remedConfigs"
  | "textHistories"
  | "confidentialities"
  | "confidentialityDemands"
  | "ecgs"
  | "ecgInterpretations"
  | "itemSubscriptions"
  | "deletedItems"
  | "sessions";

type Row = {
  id?: string | null | undefined;
  creator: User | null | undefined;
  _modifiedDate?: string | null | undefined;
  _date?: string | null | undefined;
};

export type Language = "en" | "fr" | "ar";

export type AiContextMessage = {
  role: "system" | "assistant" | "user";
  content: string;
};

export type CardType = keyof typeof cardTypes;

export type MigratedItem = Row & {
  oldTable: string;
  oldValue?: string;
  oldId: string;
  newItem: any;
};

export type MigrationSession = Row & {
  dbName: string | null | undefined;
  status: "pending" | "done" | "error";
  phase: MigrationPhase;
  itemsCount: number;
  totalItemsCount: number;
  startDatetime: string;
  currentStartDatetime?: string;
  currentEndDatetime?: string;
  shouldAddItems: boolean;
  shouldAddPatients: boolean;
  shouldAddTasks: boolean;
  shouldUpdateWaitingLists: boolean;
};

export type MigrationPhase = "users" | "patients" | "items" | "tasks";
export const migrationPhases: Record<MigrationPhase, string> = {
  users: "Utilisateurs",
  patients: "Patients",
  items: "Items",
  tasks: "Tâches",
};

//
//
//! ///////////////////////////////////////
//! ////////    DATA SERVER ///////////////////
//! ///////////////////////////////////////
//

export type ServerData = {
  ip?: string;
  port?: string;
  loggedInUser?: User | null | undefined;
  referentielConfig: RemedConfig | null | undefined;
};

export type ServerSessionData = {
  userId?: string | null | undefined;
};

//
//
//! ///////////////////////////////////////
//! ////////    USER ///////////////////
//! ///////////////////////////////////////
//

export type User = Row & {
  firstName: string;
  lastName: string;
  sex: "homme" | "femme";
  email: string;
  address: string;
  token: string | null | undefined;
  lastTokenDate: string | null | undefined;
  phoneNumber: string;
  password: string;
  profilePic: Upload | null | undefined;
  grade: Grade;
  lastOnlineDate?: string;
  patientId?: string | null | undefined;
  role: Role | null | undefined;
  workingPlan?: WorkingPlan | null | undefined;
  speciality: Speciality | null | undefined;
  service?: Service | null | undefined;
  permissions: (Permission | null | undefined)[];
  interdictions: (Permission | null | undefined)[];
  remedLicence?: string | null | undefined;
  isValidated?: boolean;
};

export type UserLight = Pick<User, "id" | "firstName" | "lastName" | "grade">;

export type Grade =
  | "Dr"
  | "Pr"
  | "Externe"
  | "Interne"
  | "Résident"
  | "Assitant"
  | "MCA"
  | "MCB"
  | "Autre";

export const allGrades: Record<
  Grade,
  {
    id: Grade;
    name: string;
  }
> = {
  Dr: {
    id: "Dr",
    name: "Docteur",
  },
  Pr: {
    id: "Pr",
    name: "Professeur",
  },
  Résident: {
    id: "Résident",
    name: "Résident",
  },
  Assitant: {
    id: "Assitant",
    name: "Assitant",
  },
  MCA: {
    id: "MCA",
    name: "Maître de conférences A",
  },
  MCB: {
    id: "MCB",
    name: "Maître de conférences B",
  },
  Externe: {
    id: "Externe",
    name: "Externe",
  },
  Interne: {
    id: "Interne",
    name: "Interne",
  },
  Autre: {
    id: "Autre",
    name: "Autre",
  },
};

export type Speciality = Row & {
  name: string;
  secondaryName: string;
  frequentReferences?: (Reference | null | undefined)[];
  initialExaminationFormulaires?: (Reference | null | undefined)[];
  evolutionFormulaires?: (Reference | null | undefined)[];
  admissionFormulaires?: (Reference | null | undefined)[];
  shiftFormulaires?: (Reference | null | undefined)[];
};

export type Permission =
  | "ecgDashboardPage"
  | "ecgsPage"
  | "ecgUsersPage"
  | "ecgProfilePage"
  | "notificationsPage"
  | "messageriePage"
  | "usersPage"
  | "rolesPage"
  | "configurationPage"
  | "referentielPage"
  | "loginPage";

export type Role = Row & {
  id?: string;
  name: string;
  color: string;
  description: string;
  permissions: (Permission | null | undefined)[];
  initialPage: Permission | null | undefined;
};

export type FrequentRole =
  | "doctor"
  | "private_doctor"
  | "patient"
  | "cardiologue"
  | "administrateur";

export const frequentRoles: Record<FrequentRole, Partial<Role>> = {
  administrateur: {
    // id: "roles:1733750194667vy57blueb",
    id: "roles:1718842166511433dko32jl",
    name: "administrateur",
    color: "purple",
  },
  doctor: {
    // id: "roles:1733750576511w4kvaige7",
    id: "roles:MA__",
    color: "blue",
    name: "doctor",
  },

  private_doctor: {
    id: "roles:Mg__",
    // id: "roles:1733750576511w4kvaige7",
    color: "blue",
    name: "private_doctor",
  },

  patient: {
    id: "roles:Njk_",
    color: "red",
    name: "patient",
  },

  cardiologue: {
    // id: "roles:1733750542697agkrso0z6",
    id: "roles:1718840693056kczpz9tbki",
    color: "red",
    name: "cardiologue",
  },
} as const;

export type WorkingPlan = Row & {
  fridayStart?: string;
  fridayEnd?: string;
  saturdayStart?: string;
  saturdayEnd?: string;
  sundayStart?: string;
  sundayEnd?: string;
  mondayStart?: string;
  mondayEnd?: string;
  tuesdayStart?: string;
  tuesdayEnd?: string;
  wednesdayStart?: string;
  wednesdayEnd?: string;
  thursdayStart?: string;
  thursdayEnd?: string;
};

export type TextHistory = Row & {
  id?: string;
  inputId: string | null | undefined;
  name: string;
  value: string;
};

//
//
//! ///////////////////////////////////////
//! //////// CONFIDENTIALITY ///////////////////
//! ///////////////////////////////////////
//
//

export type Confidentiality = Row & {
  type: ConfidentialityType;
  patient: Patient | null | undefined;
  demands: (ConfidentialityDemand | null | undefined)[] | null | undefined;
  users: (User | null | undefined)[] | null | undefined;
  services: (Service | null | undefined)[] | null | undefined;
  units: (StructureUnit | null | undefined)[] | null | undefined;
  specialities: (Speciality | null | undefined)[] | null | undefined;
};

export type ConfidentialityDemand = Row & {
  status: ConfidentialityDemandStatus;
  grantedBy: User | null | undefined;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  parentId: string | null | undefined;
  user: User | null;
};

export type ConfidentialityDemandStatus = "pending" | "granted" | "refused";
export const confidentialityDemandStatuses: Record<
  ConfidentialityDemandStatus,
  {
    id: ConfidentialityDemandStatus;
    name: string;
    color: "blue" | "green" | "red";
  }
> = {
  pending: {
    id: "pending",
    color: "blue",
    name: "En attente",
  },
  granted: {
    id: "granted",
    color: "green",
    name: "Accordé",
  },
  refused: {
    id: "refused",
    color: "red",
    name: "Refusé",
  },
};

export type ConfidentialityType =
  | "all"
  | "users"
  | "services"
  | "units"
  | "specialities";

export const confidentialityTypes: Record<
  ConfidentialityType,
  {
    id: ConfidentialityType;
    name: string;
  }
> = {
  all: {
    id: "all",
    name: "Tous",
  },
  users: {
    id: "users",
    name: "Utilisateurs",
  },
  services: {
    id: "services",
    name: "Services",
  },
  units: {
    id: "units",
    name: "Unités",
  },
  specialities: {
    id: "specialities",
    name: "Spécialités",
  },
};

//
//! ///////////////////////////////////////
//! ////////    PATIENT ITEMS ///////////////////
//! ///////////////////////////////////////
//

export type Patient = Row & {
  recordId?: string; // physical record id
  recordCreationDate?: string; // physical record creation date
  firstName?: string;
  firstNameAr?: string;
  lastName?: string;
  lastNameAr?: string;
  maidenName?: string;
  searchInfo?: string;
  searchInfoAr?: string;
  birth?: string;
  presumed?: boolean;
  birthTerm?: number; // in weeks
  sex?: "homme" | "femme";
  address?: string;
  addressAr?: string;
  nationality?: string;
  wilaya?: string;
  commune?: string;
  phoneNumber?: string;
  secondaryPhoneNumber?: string;
  email?: string;
  profilePic?: Upload | null | undefined;
  carceralStatus?: "" | "libre" | "détenu" | "gardé à vue";
  deathStatus?: "" | "vivant" | "décédé";
  deathDate?: string;
  deathCause?: string;
  familyMemberFullName?: string;
  familyMemberPhoneNumber?: string;
  familyMemberAddress?: string;

  maritalStatus?: "" | "célibataire" | "marié(e)" | "divorcé(e)" | "veuf/veuve";
  fatherName?: string;
  motherName?: string;
  spouseName?: string;
  childrenCount?: string;
  profession?: string;
  bloodType?: "A+" | "A-" | "B+" | "B-" | "AB+" | "AB-" | "O+" | "O-";
  socialSecurityNumber?: string;
  assuranceAffiliation?: "CNAS" | "CASNOS" | "";
  nin?: string;
  confidentiality: Confidentiality | null | undefined;
  services: (Service | null | undefined)[] | null | undefined;
};

export type PatientLight = Pick<
  Patient,
  | "id"
  | "firstName"
  | "lastName"
  | "birth"
  | "confidentiality"
  | "services"
  | "_date"
>;

export type Upload = Row & {
  field?: Field;
  parent?: RefAnswer | null | undefined;
  thumbnail?: string;
  name: string;
  url: string | null;
  patient: Patient | null | undefined;
  original_name: string;
  description?: string | null | undefined;
  fileType: "image" | "video" | "audio" | "document" | "other";
  confidentiality?: Confidentiality | null | undefined;
  progress?: number;
  compressionProgress?: number;
  error?: string | null;
};

export type RefAnswer = Row & {
  notes?: string | null | undefined;
  conclusion?: string | null | undefined;
  uploads?: (Upload | null | undefined)[];
  status?: PatientItemStatus;
  reference?: Reference | null | undefined;
  patient: Patient | null | undefined;
  fieldAnswers: (FieldAnswer | null | undefined)[];
  confidentiality?: Confidentiality | null | undefined;
  isAbsent?: boolean;
};

export type FieldAnswer = Row & {
  value?: any;
  field?: Field;
  parent: RefAnswer | null | undefined;
  patient: Patient | null | undefined;
};

export const patientColumnsNames: Record<keyof Patient, string> = {
  firstName: "Prénom",
  lastName: "Nom",
  firstNameAr: "Prénom (ar)",
  lastNameAr: "Nom (ar)",
  maidenName: "Nom de jeune fille",
  searchInfo: "Nom complet",
  searchInfoAr: "Nom complet (ar)",
  birth: "Date de naissance",
  birthTerm: "Terme de naissance",
  presumed: "Présumé",
  sex: "Sexe",
  address: "Adresse",
  addressAr: "Adresse (ar)",

  nationality: "Nationalité",
  wilaya: "Wilaya",
  commune: "Commune",
  phoneNumber: "Numéro de téléphone",
  secondaryPhoneNumber: "Numéro de téléphone secondaire",
  email: "Email",
  carceralStatus: "Statut carcéral",
  deathStatus: "Statut de décès",
  deathDate: "Date de décès",
  deathCause: "Cause du décès",
  familyMemberFullName: "Personne de confiance",
  familyMemberPhoneNumber: "Numéro de téléphone de la personne de confiance",
  familyMemberAddress: "Adresse de la personne de confiance",
  maritalStatus: "Statut marital",
  fatherName: "Nom du père",
  motherName: "Nom de la mère",
  spouseName: "Nom du conjoint",
  childrenCount: "Nombre d'enfants",
  profession: "Profession",
  bloodType: "Groupage",
  socialSecurityNumber: "Numéro de sécurité sociale",
  assuranceAffiliation: "Affiliation à l'assurance",
  nin: "Numéro d'identification national",
  id: "Identifiant",
  recordId: "Numéro de dossier",
  recordCreationDate: "Date de création du dossier",
  profilePic: "Photo",

  services: "Services",
  confidentiality: "Confidentialité",
  creator: "Créateur",
  _date: "Date de création",
  _modifiedDate: "Date de modification",
};

export type GeneralStatus = PatientItemStatus | ConfidentialityDemandStatus;

export type StatusChange = Row & {
  oldStatus: GeneralStatus;
  newStatus: GeneralStatus;
  item: any;
};

//
//
//! ///////////////////////////////////////
//! ////////    REFERENTIEL ///////////////////
//! ///////////////////////////////////////
//

export type Reference = Row & {
  name?: string;
  description?: string;
  externalId?: string;
  formulation?: string | null | undefined;
  children?: (Reference | null | undefined)[];
  category?: ReferenceCategory;
  cim10Code?: string | null | undefined;
  cim11Code?: string | null | undefined;
  usageCount?: number;
  sections?: (FieldsSection | null | undefined)[];
  isVisible?: boolean;
  isAddedAutomatically?: boolean;
};

export type ReferencesGroup = Row & {
  name: string;
  type: ReferencesGroupType;
  references: (Reference | null | undefined)[];
};

export type ReferencesGroupType = "exams" | "antecedents";

export const referenceGroupTypes: Record<
  ReferencesGroupType,
  {
    id: ReferencesGroupType;
    name: string;
  }
> = {
  antecedents: {
    id: "antecedents",
    name: "Antécédents",
  },
  exams: {
    id: "exams",
    name: "Examens",
  },
};

export type ReferenceCategory =
  | "physical"
  | "functional"
  | "syndrome"
  | "motive"
  | "diagnostic"
  | "intervention"
  | "mesure"
  | "allergy"
  | "riskFactor"
  | "physiologicalAntecedent"
  | "personalAntecedent"
  | "toxicAntecedent"
  | "familialAntecedent"
  | "exam"
  | "examAnalysis"
  | "examStep"
  | "appointmentPreparation"
  | "sampleType"
  | "other"
  | "imaging"
  | "biology"
  | "ecg"
  | "exploration"
  | "formulaire"
  | "cim11"
  | "cim10"
  | "toxicity"
  | "metastasis"
  | "histologicType"
  | "histologicSubType";

export const examCategoriesIds: ReferenceCategory[] = [
  "exam",
  "imaging",
  "biology",
  "ecg",
  "exploration",
  "other",
];

export const antecedentsCategoriesIds: ReferenceCategory[] = [
  "riskFactor",
  "physiologicalAntecedent",
  "personalAntecedent",
  "toxicAntecedent",
  "familialAntecedent",
];

export const referenceCategories: Record<
  ReferenceCategory,
  {
    id: ReferenceCategory;
    name: string;
    pluralName: string;
    subCategory: "reference" | "exam" | "antecedent" | "formulaire";
  }
> = {
  functional: {
    id: "functional",
    name: "Signes Fonctionnels",
    pluralName: "Signes Fonctionnels",
    subCategory: "reference",
  },
  allergy: {
    id: "allergy",
    name: "Allergie",
    pluralName: "Allergies",
    subCategory: "reference",
  },
  physical: {
    id: "physical",
    name: "Signes Physiques",
    pluralName: "Signes Physiques",
    subCategory: "reference",
  },
  syndrome: {
    id: "syndrome",
    name: "Syndrome",
    pluralName: "Syndromes",
    subCategory: "reference",
  },
  intervention: {
    id: "intervention",
    name: "Intervention",
    pluralName: "Interventions",
    subCategory: "reference",
  },
  motive: {
    id: "motive",
    name: "Motif",
    pluralName: "Motifs",
    subCategory: "reference",
  },
  diagnostic: {
    id: "diagnostic",
    name: "Diagnostic",
    pluralName: "Diagnostics",
    subCategory: "reference",
  },
  mesure: {
    id: "mesure",
    name: "Mesure",
    pluralName: "Mesures",
    subCategory: "reference",
  },

  cim11: {
    id: "cim11",
    name: "CIM11",
    pluralName: "CIM11",
    subCategory: "reference",
  },
  cim10: {
    id: "cim10",
    name: "CIM10",
    pluralName: "CIM10",
    subCategory: "reference",
  },

  toxicity: {
    id: "toxicity",
    name: "Toxicité",
    pluralName: "Toxicités",
    subCategory: "reference",
  },

  riskFactor: {
    id: "riskFactor",
    name: "Facteur de risque",
    pluralName: "Facteurs de risque",
    subCategory: "antecedent",
  },

  physiologicalAntecedent: {
    id: "physiologicalAntecedent",
    name: "Antécédent physiologique",
    pluralName: "Antécédents physiologiques",
    subCategory: "antecedent",
  },

  personalAntecedent: {
    id: "personalAntecedent",
    name: "Antécédent personnel",
    pluralName: "Antécédents personnels",
    subCategory: "antecedent",
  },

  toxicAntecedent: {
    id: "toxicAntecedent",
    name: "Antécédent toxique",
    pluralName: "Antécédents toxiques",
    subCategory: "antecedent",
  },

  familialAntecedent: {
    id: "familialAntecedent",
    name: "Antécédent familial",
    pluralName: "Antécédents familiaux",
    subCategory: "antecedent",
  },

  examAnalysis: {
    id: "examAnalysis",
    name: "Analyse",
    pluralName: "Analyses",
    subCategory: "exam",
  },

  exam: {
    id: "exam",
    name: "Examen",
    pluralName: "Examens",
    subCategory: "exam",
  },

  biology: {
    id: "biology",
    name: "Biologie",
    pluralName: "Biologie",
    subCategory: "exam",
  },

  ecg: {
    id: "ecg",
    name: "ECG",
    pluralName: "ECG",
    subCategory: "exam",
  },

  imaging: {
    id: "imaging",
    name: "Imagerie",
    pluralName: "Imagerie",
    subCategory: "exam",
  },

  exploration: {
    id: "exploration",
    name: "Explorations",
    pluralName: "Explorations",
    subCategory: "exam",
  },

  other: {
    id: "other",
    name: "Autres",
    pluralName: "Autres",
    subCategory: "exam",
  },

  formulaire: {
    id: "formulaire",
    name: "Formulaire",
    pluralName: "Formulaires",
    subCategory: "formulaire",
  },

  metastasis: {
    id: "metastasis",
    name: "Métastase",
    pluralName: "Métastases",
    subCategory: "reference",
  },

  histologicType: {
    id: "histologicType",
    name: "Type histologique",
    pluralName: "Types histologiques",
    subCategory: "reference",
  },

  histologicSubType: {
    id: "histologicSubType",
    name: "Sous-type histologique",
    pluralName: "Sous-types histologiques",
    subCategory: "reference",
  },

  examStep: {
    id: "examStep",
    name: "Etape d'examen",
    pluralName: "Etapes d'examen",
    subCategory: "reference",
  },

  appointmentPreparation: {
    id: "appointmentPreparation",
    name: "Préparation de rendez-vous",
    pluralName: "Préparations de rendez-vous",
    subCategory: "reference",
  },

  sampleType: {
    id: "sampleType",
    name: "Type d'échantillon",
    pluralName: "Types d'échantillon",
    subCategory: "reference",
  },
} as const;

export type FieldsSection = Row & {
  name: string;
  type: "simple" | "table" | "group";
  fields: (Field | null | undefined)[];
  conditionGroupStr?: string | null | undefined;
};

export type Field = Row & {
  name: string;
  placeholder: string;
  type: InputsType;
  description: string;
  defaultValue: any;
  isRequired?: boolean | null | undefined;

  source?: "legalMigration" | "migration";
  formulation?: string | null | undefined;

  oldIds?: string[] | null | undefined;
  options: (FieldOption | null | undefined)[];
  unit: FieldUnit | null | undefined;
  min?: number | null | undefined;
  max?: number | null | undefined;

  conditionGroupStr?: string | null | undefined;
};

export type FieldUnit = Row & {
  name: string;
};

export type FieldOption = Row & {
  name: string;
  description: string;
  value: any;
};

//
//
//! ///////////////////////////////////////
//! ////////    STRUCTURES ///////////////////
//! ///////////////////////////////////////
//
//

export type Hospital = Row & {
  name: string;
  address?: string | null | undefined;
  latitude?: number;
  longitude?: number;
  wilaya?: string | null | undefined;
};

export type Service = Row & {
  name: string | null | undefined;

  chef?: User | null | undefined;
  hospital?: Hospital;
  waitingScreens?: (WaitingScreen | null | undefined)[] | null | undefined;
  units?: (StructureUnit | null | undefined)[] | null | undefined;
  url?: string | null | undefined;
  type?: ServiceType | null | undefined;
  h1?: string | null | undefined;
  h2?: string | null | undefined;
  h3?: string | null | undefined;
  address?: string | null | undefined;
  header?: string | null | undefined;
  leftBar: string;
  phoneNumber1?: string | null | undefined;
  phoneNumber2?: string | null | undefined;
  phoneNumber3?: string | null | undefined;
};

export type ServiceType =
  | "hospitalisations"
  | "emergencies"
  | "biology"
  | "imaging"
  | "medicalOffice"
  | "centralPharmacy";

export const serviceTypes: Record<
  ServiceType,
  {
    id: ServiceType;
    name: string;
  }
> = {
  hospitalisations: {
    id: "hospitalisations",
    name: "Hospitalisations",
  },
  emergencies: {
    id: "emergencies",
    name: "Urgences",
  },
  biology: {
    id: "biology",
    name: "Biologie",
  },
  centralPharmacy: {
    id: "centralPharmacy",
    name: "Pharmacie centrale",
  },
  imaging: {
    id: "imaging",
    name: "Imagerie",
  },
  medicalOffice: {
    id: "medicalOffice",
    name: "Cabinet médical",
  },
};

export type StructureUnitType =
  | "hospitalisation"
  | "consultation"
  | "emergency"
  | "medecineLegale"
  | "exploration"
  | "pharmacie";

export const structureUnitTypes: Record<
  StructureUnitType,
  {
    id: StructureUnitType;
    name: string;
  }
> = {
  hospitalisation: {
    id: "hospitalisation",
    name: "Hospitalisation",
  },
  consultation: {
    id: "consultation",
    name: "Consultation",
  },
  emergency: {
    id: "emergency",
    name: "Urgence",
  },
  exploration: {
    id: "exploration",
    name: "Exploration",
  },
  medecineLegale: {
    id: "medecineLegale",
    name: "Médecine légale",
  },
  pharmacie: {
    id: "pharmacie",
    name: "Pharmacie",
  },
};

export type StructureUnit = Row & {
  name: string;

  service?: Service;
  type?: StructureUnitType;
  chef?: User | null | undefined;
  boxes?: (Box | null | undefined)[] | null | undefined;
  beds?: (Bed | null | undefined)[] | null | undefined;
};

export type Box = Row & {
  name?: string;
  unit: StructureUnit | null | undefined;

  type?: StructureUnitType;
  isDisabled?: boolean;
};

export type Bed = Row & {
  name: string;
  unit: StructureUnit;
  type?: "hospitalisation" | "cure";
};

export type WaitingScreen = Row & {
  name: string;
  type: WaitingScreenType | null;
  format: WaitingScreenFormat | null;
  description: string;
  quadrant1?: WaitingScreenQuadrant | null | undefined;
  quadrant2?: WaitingScreenQuadrant | null | undefined;
  quadrant3?: WaitingScreenQuadrant | null | undefined;
  quadrant4?: WaitingScreenQuadrant | null | undefined;
  quadrant5?: WaitingScreenQuadrant | null | undefined;
  quadrant6?: WaitingScreenQuadrant | null | undefined;
  boxes: (Box | null | undefined)[];
  leftCornerText?: string | null | undefined;
  leftCornerImage1?: string | null | undefined;
  leftCornerImage2?: string | null | undefined;
  title?: string | null | undefined;
  rightCornerText?: string | null | undefined;
  rightCornerImage1?: string | null | undefined;
  rightCornerImage2?: string | null | undefined;
  inscructionsImage?: string | null | undefined;
  gridCount?: number | null | undefined;
};

export type WaitingScreenType = "waitingRoom" | "displayScreen";

export const waitingScreenTypes: Record<
  WaitingScreenType,
  { id: WaitingScreenType; name: string }
> = {
  waitingRoom: {
    id: "waitingRoom",
    name: "Salle d'attente",
  },
  displayScreen: {
    id: "displayScreen",
    name: "Ecran d'affichage",
  },
};

export type WaitingScreenFormat =
  | "displayFormat1"
  | "displayFormat2"
  | "displayFormat3"
  | "displayFormat4"
  | "displayFormat5"
  | "displayFormat6"
  | "displayFormat7"
  | "displayFormat8";

export const waitingScreenFormats: Record<
  WaitingScreenFormat,
  { id: WaitingScreenFormat; name: string }
> = {
  displayFormat1: {
    id: "displayFormat1",
    name: "Format 1",
  },
  displayFormat2: {
    id: "displayFormat2",
    name: "Format 2",
  },
  displayFormat3: {
    id: "displayFormat3",
    name: "Format 3",
  },
  displayFormat4: {
    id: "displayFormat4",
    name: "Format 4",
  },
  displayFormat5: {
    id: "displayFormat5",
    name: "Format 5",
  },
  displayFormat6: {
    id: "displayFormat6",
    name: "Format 6",
  },
  displayFormat7: {
    id: "displayFormat7",
    name: "Format 7",
  },
  displayFormat8: {
    id: "displayFormat8",
    name: "Format 8",
  },
};

export type WaitingScreenQuadrant = Row & {
  type: WaitingScreenQuadrantType;
  file?: Upload | null | undefined;
};

export type WaitingScreenQuadrantType = "file" | "shiftsList";

// | "waitingCount"

export const waitingScreenQuadrantTypes: Record<
  WaitingScreenQuadrantType,
  { id: WaitingScreenQuadrantType; name: string }
> = {
  file: {
    id: "file",
    name: "Fichier",
  },
  shiftsList: {
    id: "shiftsList",
    name: "Liste de garde",
  },
  // waitingCount: {
  //   id: "waitingCount",
  //   name: "Patients en salle d'attente",
  // },
};

//
//
//! ///////////////////////////////////////
//! ////////    MESSAGES ///////////////////
//! ///////////////////////////////////////
//
//

export type Message = Row & {
  content: string;
  uploads?: (Upload | null | undefined)[];
  item: any;
  discussion: Discussion | null | undefined;
  readBy: (User | null | undefined)[];
};

export type Discussion = Row & {
  name: string | null | undefined;
  users: (User | null | undefined)[];
  lastMessage: Message | null | undefined;
  type: "simple" | "group" | "ticket";
  status: TicketStatus;
};

export type TicketStatus = "pending" | "finished" | "inProgress" | "canceled";

export const ticketStatuses: Record<
  TicketStatus,
  { id: TicketStatus; name: string; color: string; secondaryColor: string }
> = {
  pending: {
    id: "pending",
    name: "En attente",
    color: "rgba(255, 0, 0, 1)",
    secondaryColor: "rgba(255, 0, 0, 0.1)",
  },

  inProgress: {
    id: "inProgress",
    name: "En cours",
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  canceled: {
    id: "canceled",
    name: "Annulé",
    color: "rgba(0, 0, 255, 1)",
    secondaryColor: "rgba(0, 0, 255, 0.1)",
  },
  finished: {
    id: "finished",
    name: "Terminé",
    color: "rgba(2, 194, 75, 1)",
    secondaryColor: "rgba(2, 194, 75,0.12)",
  },
};

//
//
//! ///////////////////////////////////////
//! ////////    CONFIG ///////////////////
//! ///////////////////////////////////////
//
//

export type RemedConfig = Row & {
  version: string | null | undefined;
  hospital?: Hospital | null | undefined;
  service?: Service | null | undefined;
  radiotherapyForm?: Reference | null | undefined;
  chemotherapyForm: Reference | null | undefined;
  surgeryForm: Reference | null | undefined;
  ventilationForm: Reference | null | undefined;
  weight: Reference | null | undefined;
  height: Reference | null | undefined;
  bmi: Reference | null | undefined;
  surfaceArea: Reference | null | undefined;
  creatinineMdrd: Reference | null | undefined;

  creatinineCockcroft: Reference | null | undefined;
};

//
//
//! ///////////////////////////////////////
//! ////////    STATISTICS ///////////////////
//! ///////////////////////////////////////
//
//

export type StatisticsReportType = "clinicalData" | "standardReport";

export type StatisticsReport = Row & {
  title?: string;
  males: number;
  females: number;
  patientGroups: Record<string, string[]>;
  patientsDates: Record<string, { date: string; count: number }>;
  appointmentsDates: Record<string, { date: string; count: number }>;
  groups: StatisticsFilter[][];
  type: StatisticsReportType;
  patients: (Patient | null | undefined)[] | null | undefined;
};

export type StatisticsFilterType = "patient" | "appointment" | "refAnswer";

export type StatisticsValueType =
  | "minMaxDate"
  | "minMaxNumber"
  | "text"
  | "select"
  | "boolean";

export type StatisticsFilter = Row & {
  table: "patients" | "appointments" | "refAnswers" | "fieldAnswers";
  localId?: string | null | undefined;
  columnName: string;
  value: any;
  visibleName: string;
  visibleValue?: string | string[];
  valueType: StatisticsValueType;

  subFilters: StatisticsFilter[];
};

export type MinMax = Row & {
  min: string;
  max: string;
};

//
//
//! ///////////////////////////////////////
//! ////////    ECG plateform specific ///////////////////
//! ///////////////////////////////////////
//

export type ECG = Row & {
  patient: Patient | null;
  notes: string;
  uploads: (Upload | null | undefined)[];
  antecedents: (RefAnswer | null | undefined)[];
  interpretations: (EcgInterpretation | null | undefined)[];
  mainUpload?: Upload;
  isQuiz?  : boolean;
  displayAnswerDate? : string;
  correctAnswer? : string;
  isCorrectAnswerDisplayed? : boolean;
};

//quiz 
// quiz answers 

export type EcgInterpretation = Row & {
  content: string;
  uploads?: (Upload | null | undefined)[];
};

//
//
//! ///////////////////////////////////////
//! ////////    PATIENT ITEMS ///////////////////
//! ///////////////////////////////////////
//

export type PatientItem = {
  id?: string;
  patient: Patient | null | undefined;
  creator: User | null | undefined;
  readBy: (User | null | undefined)[] | null | undefined;
  confidentiality: Confidentiality | null | undefined;
  _modifiedDate?: string | null | undefined;
  _date?: string | null | undefined;
};

export type MesureSession = PatientItem & {
  type: "mesureSession";
  mesures: (RefAnswer | null | undefined)[] | null | undefined;
  parent?: any | null | undefined;
};

export type PatientItemStatus =
  | "pending"
  | "skipped"
  | "inProgress"
  | "waitingForValidation"
  | "validated"
  | "finished"
  | "problem"
  | "canceled"
  | "refused";

export const patientItemStatuses: Record<
  PatientItemStatus,
  {
    id: PatientItemStatus;
    name: string;
    color?: string;
    secondaryColor?: string;
    colorName?: "blue" | "orange" | "red" | "gray" | "green" | "purple";
  }
> = {
  pending: {
    id: "pending",
    name: "En attente",
  },

  skipped: {
    id: "skipped",
    name: "Passé (non effectué)",
    color: "rgba(0, 0, 0,0.8)",
    secondaryColor: "rgba(0, 0, 0,0.1)",
    colorName: "gray",
  },
  inProgress: {
    id: "inProgress",
    name: "En cours",
    colorName: "blue",
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  waitingForValidation: {
    id: "waitingForValidation",
    name: "En attente de validation",
    colorName: "orange",
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
  validated: {
    id: "validated",
    name: "Validé",
    colorName: "blue",
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  finished: {
    id: "finished",
    name: "Terminé",
    colorName: "green",
    color: "rgba(2, 194, 75, 1)",
    secondaryColor: "rgba(2, 194, 75,0.12)",
  },
  problem: {
    id: "problem",
    name: "Problème",
    colorName: "red",
    color: "rgba(255, 0, 0,1)",
    secondaryColor: "rgba(255, 0, 0,0.1)",
  },
  canceled: {
    id: "canceled",
    name: "Annulé",
    colorName: "red",
    color: "rgba(255, 0, 0,1)",
    secondaryColor: "rgba(255, 0, 0,0.1)",
  },
  refused: {
    id: "refused",
    name: "Refusé",
    colorName: "red",
    color: "rgba(255, 0, 0,1)",
    secondaryColor: "rgba(255, 0, 0,0.1)",
  },
};

//
//! ///////////////////////////////////////
//! ////////    NOTIFICATIONS  ///////////////////
//! ///////////////////////////////////////
//
export type Notification = Row & {
  type: NotificationType;
  status: NotificationStatus;
  item: any;
  service?: Service | null | undefined;
  patient?: Patient | null | undefined;
  targetUsers: (User | null | undefined)[] | null | undefined;
  readBy: (User | null | undefined)[];
};

export type ItemSubscription = Row & {
  item: any;
  user: User | null | undefined;
  patient?: Patient | null | undefined;
};

export type NotificationType =
  | "newEcg"
  | "newQuiz"
  | "quizResponse"
  | "newEcgInterpretation"
  | "examResult"
  | "itemChange"
  | "message"
  | "confidentialityDemand"
  | "confidentialityAnswer";

export const notificationTypes: Record<
  NotificationType,
  {
    id: NotificationType;
    name: string;
    body: string;
  }
> = {
  newEcg: {
    id: "newEcg",
    name: "Nouvel ECG",
    body: "Un nouvel ECG a été ajouté",
  },
  newQuiz: {
    id: "newQuiz",
    name: "Nouveau Quiz",
    body: "Un nouvel Quiz a été ajouté",
  },
  quizResponse: {
    id: "quizResponse",
    name: "Réponse au Quiz",
    body: "La réponse du quiz est publiée",
  },
  newEcgInterpretation: {
    id: "newEcgInterpretation",
    name: "Nouvelle interprétation d'ECG",
    body: "Une nouvelle interprétation d'ECG a été ajoutée",
  },
  examResult: {
    id: "examResult",
    name: "Résultat d'examen",
    body: "Un nouveau résultat d'examen a été ajouté",
  },
  message: {
    id: "message",
    name: "Message",
    body: "Vous avez reçu un nouveau message",
  },

  confidentialityDemand: {
    id: "confidentialityDemand",
    name: "Demande d'accès à un élément",
    body: "Une nouvelle demande d'accès à un élément a été ajoutée",
  },
  confidentialityAnswer: {
    id: "confidentialityAnswer",
    name: "Réponse à une demande de confidentialité",
    body: "Une réponse à une demande de confidentialité a été ajoutée",
  },
  itemChange: {
    id: "itemChange",
    name: "Modification d'un élément",
    body: "Un élément que vous suivez a été modifié",
  },
};

export type NotificationStatus =
  | "pending"
  | "finished"
  | "canceled"
  | "deleted";
export const notificationStatuses: Record<
  NotificationStatus,
  {
    id: NotificationStatus;
    name: string;
  }
> = {
  pending: {
    id: "pending",
    name: "En attente",
  },
  finished: {
    id: "finished",
    name: "Fait",
  },
  canceled: {
    id: "canceled",
    name: "Annulé",
  },
  deleted: {
    id: "deleted",
    name: "Supprimé",
  },
};

export type CardItemType =
  | "simple"
  | "gray"
  | "red"
  | "green"
  | "blue"
  | "orange"
  | "purple"
  | "pink"
  | "white"
  | "consultation"
  | "evolution"
  | "shift"
  | "score"
  | "story"
  | "intervention"
  | "ventilation"
  | "initialExamination"
  | "admission"
  | "billet"
  | "avis"
  | "consigne"
  | "mesure"
  | "metastasis"
  | "examStep"
  | "upload"
  | "examDemand"
  | "toxicity"
  | "radiotherapy"
  | "surgery"
  | "cure"
  | "exam"
  | "diagnostic"
  | "appointment"
  | "document"
  | "ordonnance"
  | "hospitalisation"
  | "sample";

export type InputsType =
  | "variable"
  | "text"
  | "textarea"
  | "htmlarea"
  | "select"
  | "select-multiple"
  | "dialogSelect"
  | "chips"
  | "chips-multiple"
  | "number"
  | "date"
  | "datetime"
  | "upload"
  | "uploads"
  | "boolean"
  | "password";

export const inputTypes: Record<
  InputsType,
  {
    id: InputsType;
    name: string;
  }
> = {
  variable: {
    id: "variable",
    name: "Variable",
  },
  text: {
    id: "text",
    name: "Texte",
  },
  textarea: {
    id: "textarea",
    name: "Zone de texte libre",
  },
  htmlarea: {
    id: "htmlarea",
    name: "Zone de texte riche",
  },
  select: {
    id: "select",
    name: "Menu déroulant",
  },
  "select-multiple": {
    id: "select-multiple",
    name: "Menu déroulant multiple",
  },
  dialogSelect: {
    id: "dialogSelect",
    name: "Sélection dans une boite de dialogue",
  },
  chips: {
    id: "chips",
    name: "Chips",
  },
  "chips-multiple": {
    id: "chips-multiple",
    name: "Chips multiple",
  },
  number: {
    id: "number",
    name: "Nombre",
  },
  date: {
    id: "date",
    name: "Date",
  },
  datetime: {
    id: "datetime",
    name: "Date et heure",
  },
  upload: {
    id: "upload",
    name: "Pièce jointe unique",
  },
  uploads: {
    id: "uploads",
    name: "Pièces jointes multiples",
  },
  boolean: {
    id: "boolean",
    name: "Oui/Non",
  },
  password: {
    id: "password",
    name: "Mot de passe",
  },
};

export const cardTypes = {
  simple: {
    id: "simple",
    name: "Simple",
    icon: null,
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  gray: {
    id: "gray",
    name: "Gris",
    icon: null,
    color: "rgba(0, 0, 0,0.8)",
    secondaryColor: "rgba(0, 0, 0,0.1)",
  },
  red: {
    id: "red",
    name: "Rouge",
    icon: null,
    color: "rgba(255, 0, 0,1)",
    secondaryColor: "rgba(255, 0, 0,0.1)",
  },
  green: {
    id: "green",
    name: "Vert",
    icon: null,
    color: "rgba(2, 194, 75, 1)",
    secondaryColor: "rgba(2, 194, 75,0.12)",
  },
  blue: {
    id: "blue",
    name: "Bleu",
    icon: null,
    color: "rgba(0, 85, 255,1)",
    secondaryColor: "rgba(0, 85, 255,0.1)",
  },
  orange: {
    id: "orange",
    name: "Orange",
    icon: null,
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
  purple: {
    id: "purple",
    name: "Violet",
    icon: null,
    color: "rgba(144, 0, 255,1)",
    secondaryColor: "rgba(144, 0, 255,0.1)",
  },
  pink: {
    id: "pink",
    name: "Rose",
    icon: null,
    color: "rgba(255, 77, 115,1)",
    secondaryColor: "rgba(255, 77, 115,0.1)",
  },
  white: {
    id: "white",
    name: "Blanc",
    icon: null,
    color: "rgba(255, 255, 255,1)",
    secondaryColor: "rgba(255, 255, 255,0.1)",
  },
};
